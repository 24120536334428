#main-content {
  position: relative;
  min-height: 100%;
  bottom: 0;
}

.sh-main__container {
  //position: relative;
  //width: 100%;
  //height: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  > *:not(router-outlet) {
    flex: 1;
    display: block;
  }
}

.wrapper {
  height: 100vh!important;
  margin-top: auto!important;
  margin-bottom: 0;
}

.sh-logo {
  height: 50px;
  width: auto;
}

.sh-heading {
  margin-top: 4px;
}

.policy-container {
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 13px;
  word-wrap: normal;
  max-width: 1000px;
}

.sh-modal {
  width: 600px;
}

.sh-w100 {
  width: 100%;
}

.sh-w-auto {
  width: auto;
}

.sh-min-w150 {
  min-width: 150px;
}

.sh-h100 {
  height: 100%;
}

.sh-h100vh {
  height: 100vh;
}

.sh-h80vh {
  height: calc(100vh - 242px);
}

.sh-h30px {
  height: 30px;
}

.sh-h250px {
  height: 250px;
}

.sh-position-abs {
  position: absolute;
}

.sh-container__flex {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  height: calc(100% - 70px);
  width: 100%;
  justify-content: center;
}

@media (max-width: 1599px) {
  .sh-modal {
    width: 600px;
    left: -50px
  }
}
@media (min-width: 1600px) {
  .sh-modal {
    width: 800px;
    left: -100px;
  }
}
