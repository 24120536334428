.sh-thumbnail-size {
  width: 100px;
  height: auto;
}

.sh-icon-50 {
  display: block;
  font-size: 60px;
  width: 60px;
  height: 60px;
}
