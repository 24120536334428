@import '../basics/index';

$sh-inner-corner-margin: 2px;

.sh-container {
    margin: 5px 10px;
}

.sh-dropdown-container {
    width: 100%;
    background-color: $sh-color-primary;
}

.sh-options-container {
    //max-height: 500px;
    display: inline-block;
    overflow: scroll;
    position: relative;
    scrollbar-width: none;
    //border-radius: 5px;

    &::-webkit-scrollbar {
        display: none;
        overflow: hidden;
    }
}

.sh-m-w-__300 {
    max-width: 300px;
}

.sh-options-container::-webkit-scrollbar {
    display: none;
}

.sh-border-top-accent {
    border-top: 1px solid $sh-color-accent;
}

.sh-border-round-10 {
    border-radius: 10px;
}

.sh-border-top-15 {
    border-radius: 15px;
}
.sh-form--login {
    margin: 0;
}
.login-form-container {
    padding: 20px;
    width: 100%;
    position: relative;
}

.offcanvas-body {
    padding: 0 !important;
}
.sh-dialog-container {
    & > .mat-dialog-container {
        padding: 0 !important;
        background: transparent;
    }
}

.mat-dialog-container {
}

.sh-top-corners {
    position: absolute;
    top: $sh-inner-corner-margin;
    left: $sh-inner-corner-margin;
    right: $sh-inner-corner-margin;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        border-color: #4a9fdb59;
        border-style: solid;
        position: absolute;
        top: 0;
    }

    &::before {
        border-width: 1px 0 0 1px;
        left: 0;
    }
    &::after {
        border-width: 1px 1px 0 0;
        right: 0;
    }
}

.sh-bottom-corners {
    position: absolute;
    bottom: $sh-inner-corner-margin;
    left: $sh-inner-corner-margin;
    right: $sh-inner-corner-margin;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        border-color: #4a9fdb59;
        border-style: solid;
        position: absolute;
        bottom: 0;
    }

    &::before {
        border-width: 0 0 1px 1px;
        left: 0;
    }
    &::after {
        border-width: 0 1px 1px 0;
        right: 0;
    }
}

.sh-cc-heading {
    flex: none;
    font-size: 1.5rem !important;
    padding: 2px 5px 8px 5px !important;
    position: relative;
    background: #091c26bd;
}

.sh-tooltip {
    background: #091c26c3;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(45deg, #8bbde059, #27353f59, #4a9fdb59) 1;
    backdrop-filter: $sh-color-content-backdrop;
    border-radius: 0;
    padding: 15px;
    font-size: 14px;
}

/* Media Queries */
@media screen and (max-width: 912px) {
    .sh-options-container {
        padding: 1px 2px;
        width: 100%;
        font-size: 1rem;
    }
}
