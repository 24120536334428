@keyframes sh-table-entrance-up {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes sh-table-entrance-down {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sh-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  line-height: 50px;
}

mat-table.sh-sci-fi-table {
  background: none !important;

  & mat-row {
    position: relative;
    margin: 12px 0 0 0;
    background: $sh-color-content-background;
    backdrop-filter: $sh-color-content-backdrop;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 3.5s all;

    box-shadow: inset 0 0 20px #1c69a0ab;

    &.sh-table-detail-row,
    &.sh-table-mobile-row {
      margin: 0;
    }

    &.sh-table-selected-row {
      background: #1c69a0ab;
      box-shadow: inset 0 0 25px 2px #1c69a0;
    }

    &::before {
      content: "";
      display: block;
      width: 2px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -2px;
      background: #1c69a0;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s all;
      box-shadow: 0 0 15px #2a9df0eb;
    }

    &:hover {
      transform: rotateY(0deg) !important;
      transform: scale(1.02) !important;
      box-shadow: inset 0 0 25px 2px #36a7f8;

      &::before {
        width: 4px;
        left: -7px;
      }

      & mat-cell {
        &::before {
          background: #3097e0;
        }
      }
    }
  }

  & mat-cell {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: flex-start;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(45deg, #8bbde059, #27353f59, #4a9fdb59) 1;

    &.sh-table-cell-second {
      position: relative;
      padding: 0;
      background: #091c2699;
      align-self: stretch;
      border: none;
      justify-content: center;
      align-items: center;

      &::before {
        display: none;
      }

      & .shop-link {
        align-self: flex-end;
        margin: 10px;
        border: none;
      }
    }

    & h5 {
      animation: sh-table-entrance-up 0.5s ease-in-out;
      margin-bottom: 0;
    }
    
    & span {
      animation: sh-table-entrance-down 0.5s ease-in-out;
    }
  }

  &.Sell {
    & mat-row {
      background: #134d576e;

      box-shadow: inset 0 0 20px #1c71806e;

      &::before {
        background: #29a5bb8e;
        box-shadow: 0 0 15px #29a5bbcc;
      }

      &:hover {
        box-shadow: inset 0 0 25px 2px #29a5bb;

        & mat-cell {
          &::before {
            background: #29a5bb;
          }
        }
      }
    }

    & mat-cell {
      border-image: linear-gradient(45deg, #8bbde059, #27353f59, #8bbde059) 1;

      &::before {
        box-shadow: 0 0 20px #1c71806e;
      }
    }
  }

  &.Sell {
    & mat-row {
      background: #134d576e;
  
      box-shadow: inset 0 0 20px #1c71806e;

      &::before {
        background: #29a5bb8e;
        box-shadow: 0 0 15px #29a5bbcc;
      }
  
      &:hover {
        box-shadow: inset 0 0 25px 2px #29a5bb;

        & mat-cell {
          &::before {
            background: #29a5bb;
          }
        }
      }
    }
  
    & mat-cell {
      border-image: linear-gradient(45deg, #8bbde059, #27353f59, #8bbde059) 1;

      &::before {
        box-shadow: 0 0 20px #1c71806e;
      }
    }
  }
}

.sh-table-frame {
  h2 {
    font-size: 1.5rem;
    padding: 2px 5px 8px 5px !important;
    position: relative;
    background: #091c26bd;
  }
}

.sh-table-heading {
  margin: 20px 0 10px 0;
}

.actuality-indicator {
  border-radius: 2px;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.sh-progress-indicator {
  position: absolute;
  left: 0;
  height: 100%;
  background-image: repeating-linear-gradient(to right, #1c69a030 0px, #1c69a030 5px, #091c2641 5px, #091c2641 7px);
}

.sh-progress-label {
  background: rgba(0, 0, 0, 0.35);
}

.sh-quantity-label {
  position: relative;
  padding: 8px;
  font-weight: bold;
  line-height: 15px;
  font-size: 1.25rem;

  & span {
    color: #a7daff !important;
    font-size: 10px;
    text-align: right;
    display: block;
  }
}