@import "../basics/index";

.sh-site-footer {
  position: relative;
  clear: both;
  margin-top: auto;
  margin-right: 0;
  margin-left: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 130px;
  background-color: $sh-color-primary;
}

.sh-legal-notice {
  display: inline-block;
  position: relative;
  color: #FBFEFF;
  font-size: .8rem;
  min-height: 70px;
  width: 40%;
  border-right: 1px solid $sh-color-text--light;
}

.sh-community-logo {
  right: 0;
  max-height: 100px;
  width: auto;
}

.sh-link-box {
  display: inline-block;
  max-width: 25%;
  flex: content;
  font-size: 1rem;
  padding-top: 1rem;
}
