@import "../basics/index";

.sh-btn-group {
  width: 100%;
  & > button {
    width: auto;
    margin: 0 5px 0 0;
  }
}

.sh-btn {
  height: auto;
  border: 1px solid transparent;
  border-radius: 0.3em;
  cursor: url("../../assets/images/cursor_pointer.svg"), pointer;

  padding: 8px 10px;
  text-rendering: auto;
  text-indent: 0;
  letter-spacing: 1px;
  font-size: 0.8rem;

  align-items: flex-start;
  display: inline-block;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    background-color: lightgray;
    color: darkgray;
  }
}

.sh-btn-primary:not(:disabled) {
  background-color: $sh-color-primary;
  color: $sh-color-text--light;

  &:link {
    background-color: $sh-color-primary;
    color: $sh-color-text--light;
  }

  &:hover {
    background-color: lighten($sh-color-primary, 10%);
  }

  &:active {
    background-color: darken($sh-color-secondary, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-secondary:not(:disabled) {
  background-color: $sh-color-secondary;
  color: $sh-color-text--light;

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-secondary, 10%);
  }

  &:active {
    background-color: darken($sh-color-secondary, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-accent:not(:disabled) {
  background-color: $sh-color-accent;
  color: $sh-color-text--dark;

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-accent, 10%);
  }

  &:active {
    background-color: darken($sh-color-accent, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-success:not(:disabled) {
  background-color: $sh-color-success;
  color: $sh-color-text--dark;

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-success, 10%);
  }

  &:active {
    background-color: darken($sh-color-success, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-info:not(:disabled) {
  background-color: $sh-color-info;
  color: $sh-color-text--dark;

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-info, 10%);
  }

  &:active {
    background-color: darken($sh-color-info, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-danger:not(:disabled) {
  background-color: $sh-color-danger;
  color: $sh-color-text--light;

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-danger, 10%);
  }

  &:active {
    background-color: darken($sh-color-danger, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-outline-primary:not(:disabled) {
  background-color: transparent;
  border: 1px solid $sh-color-primary;
  border-color: rgba(243,246,247,0.51);
  color: $sh-color-primary;

  &:link {

  }

  &:hover {
    background-color: $sh-color-primary;
    color: $sh-color-text--light
  }

  &:active {
    background-color: darken($sh-color-primary, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-outline-secondary:not(:disabled) {
  background-color: transparent;
  border: 1px solid $sh-color-secondary;
  color: $sh-color-secondary;

  &:link {

  }

  &:hover {
    background-color: $sh-color-secondary;
    color: $sh-color-text--light
  }

  &:active {
    background-color: darken($sh-color-secondary, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-outline-accent:not(:disabled) {
  background-color: transparent;
  border: 1px solid rgba(155, 163, 168, 0.3);
  color: $sh-color-accent;

  &:link {

  }

  &:hover {
    background-color: rgba(0, 218, 241, 0.1);

    color: $sh-color-text--light;
  }

  &:active {
    background-color: darken($sh-color-accent, 10%);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-outline-info:not(:disabled) {
  background-color: transparent;
  border: 1px solid $sh-color-light-gray;
  color: $sh-color-info;

  &:link {

  }

  &:hover {
    background-color: $sh-color-info;
    color:$sh-color-text--dark;
  }

  &:active {
    background-color: darken($sh-color-info, 10%);
    color:$sh-color-text--dark;
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-outline-success:not(:disabled) {
  background-color: transparent;
  border: 1px solid $sh-color-light-gray;
  color: $sh-color-success;

  &:link {

  }

  &:hover:not(:disabled) {
    background-color: $sh-color-success;
    color:$sh-color-text--dark;
  }

  &:active {
    background-color: darken($sh-color-success, 10%);
    color:$sh-color-text--dark;
  }
  &:focus {
    outline-width: thin;
  }
  &:disabled {
    border-color: $sh-color-light-gray;
    color: $sh-color-light-gray;
  }
}

.sh-btn-outline-danger:not(:disabled) {
  background-color: transparent;
  border: 1px solid rgba(155, 163, 168, 0.5);
  color: $sh-color-danger;

  &:link {

  }

  &:hover {
    background-color: $sh-color-danger;
    color: $sh-color-text--light;
  }

  &:active {
    background-color: darken($sh-color-danger, 10%);
    color: $sh-color-text--light;
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-raised-primary:not(:disabled) {
  background-color: $sh-color-primary;
  color: $sh-color-text--light;
  box-shadow: 0 3px 1px -2px ($sh-color-text--dark), 0 2px 2px 0 ($sh-color-text--dark), 0 1px 5px 0 ($sh-color-text--dark);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-primary, 10%);
  }

  &:active {
    box-shadow: 0 0 1px -2px ($sh-color-text--dark), 0 0 2px 0 ($sh-color-text--dark), 0 0 5px 0 ($sh-color-text--dark);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-raised-secondary:not(:disabled) {
  background-color: $sh-color-secondary;
  color: $sh-color-text--light;
  box-shadow: 0 3px 1px -2px ($sh-color-text--dark), 0 2px 2px 0 ($sh-color-text--dark), 0 1px 5px 0 ($sh-color-text--dark);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-secondary, 10%);
  }

  &:active {
    box-shadow: 0 0 1px -2px ($sh-color-text--dark), 0 0 2px 0 ($sh-color-text--dark), 0 0 5px 0 ($sh-color-text--dark);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-raised-info:not(:disabled) {
  background-color: $sh-color-info;
  color: $sh-color-text--dark;
  box-shadow: 0 3px 1px -2px ($sh-color-text--dark), 0 2px 2px 0 ($sh-color-text--dark), 0 1px 5px 0 ($sh-color-text--dark);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-info, 10%);
  }

  &:active {
    box-shadow: 0 0 1px -2px ($sh-color-text--dark), 0 0 2px 0 ($sh-color-text--dark), 0 0 5px 0 ($sh-color-text--dark);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-raised-accent:not(:disabled) {
  background-color: $sh-color-accent;
  color: $sh-color-text--light;
  box-shadow: 0 3px 1px -2px ($sh-color-text--dark), 0 2px 2px 0 ($sh-color-text--dark), 0 1px 5px 0 ($sh-color-text--dark);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-accent, 10%);
  }

  &:active {
    box-shadow: 0 0 1px -2px ($sh-color-text--dark), 0 0 2px 0 ($sh-color-text--dark), 0 0 5px 0 ($sh-color-text--dark);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-raised-success:not(:disabled) {
  background-color: $sh-color-success;
  color: $sh-color-text--light;
  box-shadow: 0 3px 1px -2px ($sh-color-text--dark), 0 2px 2px 0 ($sh-color-text--dark), 0 1px 5px 0 ($sh-color-text--dark);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-success, 10%);
  }

  &:active {
    box-shadow: 0 0 1px -2px ($sh-color-text--dark), 0 0 2px 0 ($sh-color-text--dark), 0 0 5px 0 ($sh-color-text--dark);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-btn-raised-danger:not(:disabled) {
  background-color: $sh-color-danger;
  color: $sh-color-text--light;
  box-shadow: 0 3px 1px -2px ($sh-color-text--dark), 0 2px 2px 0 ($sh-color-text--dark), 0 1px 5px 0 ($sh-color-text--dark);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:link {

  }

  &:hover {
    background-color: lighten($sh-color-danger, 10%);
  }

  &:active {
    box-shadow: 0 0 1px -2px ($sh-color-text--dark), 0 0 2px 0 ($sh-color-text--dark), 0 0 5px 0 ($sh-color-text--dark);
  }
  &:focus {
    outline-width: thin;
  }
}

.sh-button-group {
  display: inline-flex;
  flex: auto;
}

.sh-btn-round {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

mat-button-toggle {
  .sh-top-corners,
  .sh-bottom-corners {
      display: none;

      &::before,
      &::after {
          border-color: $sh-color-text--dark;
      }
  }
}

::ng-deep .mat-button-toggle-checked {
  background-color: $sh-color-accent;
  color: $sh-color-text--dark !important;

  & .mat-button-toggle-label-content {
      width: 100%;
  }

  .sh-top-corners,
  .sh-bottom-corners {
      display: block !important;
  }
}
