@import "colors";

.sh-border-primary {
  border: 1px solid $sh-color-primary
}

.sh-border-bottom-accent {
  border-bottom: 1px solid $sh-color-accent;
}

.sh-border-bottom-gradient {
  border-bottom: 1px solid $sh-color-accent;
}

.sh-border--bottom__light {
  border-bottom: 1px solid $sh-color-text--light;
}

.sh-border-image-white {
  border: 16px solid;
  border-image-source: url("../../assets/images/border_rounded_white.png");
  border-image-slice: 16 76 fill;
  z-index: 3;
}

.no-outline {
  outline: none;
}
