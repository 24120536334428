@import "../basics/index";

.sh-group {
  padding: 6px 8px;
  margin: 5px;
  border-bottom: 1px solid gray;

  .sh-group-header {
    color: rgba(112, 112, 112, 0.7);
  }

  .sh-group-item {
    padding: 2px 3px;
    color: $sh-color-primary;
    width: 100%;


    &:link {

    }

    &:active {
      background-color: $sh-color-accent;
    }
  }
}
