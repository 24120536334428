.sh-form {
  display: flex;
  margin: 10px 25px;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;

  &>mat-form-field {
    margin-bottom: 20px;
    width: 100%;
  }
}

.sh-form-group {
  padding: 20px;
  border: 2px solid white;
  border-radius: 4px;
  margin: 10px;
}

.sh-form-group>label{
  position: absolute;
  display: block;
  top: -2px;
  left: 20px;
  padding: 0 10px;
}
