@import "basics";

.sh-selection-list {
  min-width: 100px;
  max-width: 250px;
  & mat-list-option {
    background-color: #00a3f1;
  }
}

.sh-add-price-tooltip {
  background: $sh-color-content-background !important;
  box-shadow: $sh-color-content-shadow !important;
  backdrop-filter: $sh-color-content-backdrop !important;
  border-radius: 0 !important;
  padding: 10px !important;
  font-size: 14px !important;
}
