@import "../basics/colors";
.head-nav-wrapper {
  //background-image: url("../../assets/images/nav_bg.png");
  background-repeat: no-repeat;
  background-color: $sh-color-background-main;
}


.sh-navbar a {
  float: left;
  color: $sh-color-text--light;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.sh-subnav {
  float: left;
  overflow: hidden;
}

.sh-subnav .btn-subnav {
  border: none;
  outline: none;
  color: $sh-color-text--light;
  padding: 14px 16px;
  background-color: inherit;
  marging: 0;
}

.sh-subnav-list {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  line-height: 1.4rem;
  border-top: 1px solid $sh-color-accent;
}

.sh-subnav-item {
  text-align: center;
  min-height: 48px;
  width: 150px;
  text-align: center;
  padding-left: 0;
  padding-top: 10px;
  list-style: none;
  position: relative;
  &:after {
    width: 2px;
    height: 40px;
    background-color: $sh-color-accent;
    transform: rotate(15deg);
    content: '';
    margin-right: 0;
    position: absolute;
    display:inline-block;
    top: 20px;
    right: 0;
  }
}

.sh-top-navbar {
  background-color: $sh-color-secondary;
  color: $sh-color-text--light;
}

.sh-tool-nav-item {
  display: flex;
  width: 155px;
  text-align: center;
  margin-bottom: 0;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0;
  padding-top: .2rem;
  padding-bottom: .2rem;
  list-style: none;
}

.sh-tool-nav-item > div > img {
  width: 50px;
  height: auto;
}

.sh-subnav-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: $sh-color-secondary;
  width: 100%;
  z-index: 1;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.sh-subnav-content a {
  float: left;
  color: $sh-color-text--light;
  text-decoration: none;
}

.sh-subnav-content a:hover {
  background-color: $sh-color-accent;
  color: black;
}

.sh-subnav:hover .sh-subnav-content {
  display: block;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.sh-navbar {

  color: $sh-color-text--light;
  font-size: 1em;
  width: 100%;

  .sh-nav-list {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0 20px;
  }

  .sh-nav-link {
    border: none;
    outline: none;
    display: inline-block;
    font-weight: 600;
    line-height: 30px;
    color: $sh-color-text--light;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    width: 100%;
    height: 30px;
    background-color: transparent;
  }

  .sh-nav-link:not(:disabled):hover {
    text-decoration: none;
    color: $sh-color-accent;
    border: none;
    outline: none;

    &::before {
      content: '';
      background-color: $sh-color-secondary;
      position: absolute;
      top: -10px;
      right: 3px;
      bottom: -8px;
      left: 1px;
      transform: skew(-15deg);
      opacity: 1;
      z-index: -1;
    }
  }

  .sh-nav-link:disabled {
    border: none;
    outline: none;
    cursor: no-drop;
    color: $sh-color-light-gray;
  }

  .sh-nav-link:link {
    display: block;
    color: $sh-color-text--light;
  }

  .sh-nav-link:active {
    text-decoration: none;
    color: $sh-color-accent;
  }

  .sh-nav-list-item {
    height: 48px;
    width: 150px;
    text-align: center;
    padding-top: 10px;
    box-sizing: border-box;
  }

  .sh-nav-list-item:disabled {
    cursor: no-drop;
    color: $sh-color-light-gray;
  }

  .sh-nav-list-item:hover {
    .sh-nav-link:not(:disabled) {
      z-index: 1;
    }
    color: $sh-color-accent;
    cursor: url("../../assets/images/cursor_pointer.svg"), pointer;
  }
}

.navbar-nav {
  align-items: center;
  gap: 6px;
}

.dropdown-submenu{
  position: absolute;
  list-style: none;
  top: -25px!important;
  left: -10px;
  margin-left: 0;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 6px;
  font-size: 0.8em;
}

.submenu-item {
  list-style: none;
  &:hover {
    background-color: #00daf1;
  }
}

.dropdown-item:active,
.dropdown-item:hover{color:$sh-color-text--dark;background-color: $sh-color-accent;}
.sh-nav-menu-container {
  display: flex;
  height: 81px;
}

.sh-nav-menu-list-container {
  width: 100%;
  height: 66px;
  background-color: $sh-color-primary;
  display: flex;
}

.sh-nav-menu-list {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.4rem;
}

.sh-nav-menu-list li {
  position: relative;
}

.sh-nav-menu-list li:after {
  content: '';
  width: 2px;
  background-color: $sh-color-accent;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transform: skew(-15deg);
  z-index: 1;
}

.sh-nav-menu-item {
  display: inline-block;
  height: 81px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  box-sizing: border-box;
}

.sh-navbar ul {
  list-style: none;
}

.sh-subnav-list-item {
  height: 48px;
  width: 150px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  box-sizing: border-box;
  &:hover {
    cursor: url("../../assets/images/cursor_pointer.svg"), pointer;
  }
}

.sh-subnav-list-item:after {
  width: 2px;
  height: 40px;
  background-color: $sh-color-accent;
  content: '';
  margin-right: -20px;
  position: relative;
  float:right;
  display:block;
  top: 5px;
}

.sh-menu {
  background-color: $sh-color-primary;
  height: 71px;
  padding-top: 4px;
  position: relative;
  width: 100%;
}

.sh-nav-menu {
  clear: both;
}

.sh-btn-nav {
  color: $sh-color-accent;
  text-align: center;
  width: 150px;
  font-size: .8em;
  padding-top: 9px;

  &:hover {
    cursor: url("../../assets/images/cursor_pointer.svg"), pointer;
    img {
      filter: drop-shadow(0px 0px 1px $sh-color-accent) brightness(200%);
    }
  }
  img {
    width: auto;
    height: 35px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}


.sh-manufacturer-select-logo {
  height: 40px;
  width: auto;
}

.sh-manufacturer-select-list {
  overflow-x: scroll;
  overflow-y: hidden;
  cursor: grab;
}
.manufacturer-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px 0;
  padding: 10px;
  border: 1px solid $sh-color-light-gray;
  grid-template-rows: 50px;

  & grid-row-end {
    border-bottom: none;
  }
}

@media screen and (max-width: 900px) {
  .manufacturer-grid {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 5px 0;
    padding: 10px;
    border: 1px solid $sh-color-light-gray;
    grid-template-rows: 50px;
    width:max-content;
  }

  .manufacturer-grid-item {
    height: 60px;
    font-size: 0.8em;
    text-align: start;
    position: relative;
    filter: brightness(80%);
    color: $sh-color-text--light;
    vertical-align: middle;

    & > label {
      position: relative;
      top: calc(50% - 25px);
      width: 590px;
      padding: 15px 40px;
      &:hover {
        cursor: url("../../assets/images/cursor_pointer.svg"), pointer;
      }
    }

    & > div {
      position: absolute;
      right: 4px;
      top: calc(50% - 20px);
      align-content: center;
      width: auto;
      & > img {
        display: block;
        margin-left: auto;
        margin-right: 0;
      }
    }

    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      left: 0;
      top: calc(50% - 10px);
      border: 2px solid $sh-color-secondary;
      border-radius: 50%;
    }

    &:not(last-child) {
      border-bottom: 1px solid $sh-color-light-gray;
    }
  }
}

.manufacturer-grid-item {
  height: 60px;
  font-size: 0.8em;
  text-align: start;
  position: relative;
  filter: brightness(80%);
  color: $sh-color-text--light;
  vertical-align: middle;

  & > label {
    position: relative;
    top: calc(50% - 25px);
    width: 100%;
    padding: 15px 40px;
    &:hover {
      cursor: url("../../assets/images/cursor_pointer.svg"), pointer;
    }
  }

  & > div {
    position: absolute;
    text-align: right;
    right: 4px;
    top: calc(50% - 20px);
    align-content: center;
    width: 140px;
    & > img {
      display: block;
      margin-left: auto;
      margin-right: 10px;
    }
  }

  &::before {
    position: absolute;
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    top: calc(50% - 10px);
    border: 2px solid $sh-color-secondary;
    border-radius: 50%;
  }

  &:not(last-child) {
    border-bottom: 1px solid $sh-color-light-gray;
  }
}

.manufacturer-grid-item:hover {
  filter: brightness(100%);
  &::before {
    background-color: $sh-color-accent;
  }
}

ul {
  margin-bottom: 0;
}

.sh-dropdown-menu {
  width: 1024px;
  background-color: $sh-color-primary;
}

.dropdown-menu {
  padding: 0;
}

.disabled {
  color: $sh-color-light-gray !important;
  cursor: no-drop;
}
