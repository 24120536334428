@import "../basics/colors";
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-input-element {
  caret-color: white!important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #00a3f1!important;
}

.mat-menu-panel {
  background-color: $sh-color-primary!important;
}

.sh-input {
  border: 1px solid transparent;
  border-radius: .3em;
  text-align: center;
  background-color: $sh-color-secondary;
  caret-color: #FBFEFF;

  color: $sh-color-text--light;
  padding: 5px 15px;

  &:focus {
    border: none;
    outline: none;
    caret-color: #FBFEFF;
  }

  &::placeholder {
    color: $sh-color-light-gray;
  }

  &:placeholder-shown {
    color: $sh-color-text--light;
    opacity: 0.8;
    font-size: .8em;
    vertical-align: middle;
  }
  &::-ms-input-placeholder {
    color: $sh-color-text--light;
    opacity: 0.9;
    font-size: .8em;
    vertical-align: middle;
  }
  &::-webkit-input-placeholder {
    color: $sh-color-text--light;
    font-size: .8em;
    opacity: 0.9;
    vertical-align: middle;
  }
}

.sh-form-control {
  display: block;
  width: 100%;
  margin-bottom: 4px;
}

.sh-input-group {
  max-width: 300px;
  align-self: center;
  padding: 0 10px;
}

.sh-input-group-top {
  max-width: 300px;
  align-self: auto;
  margin: 10px 10px;
}


.sh-search-field {
  border: 1px solid $sh-color-text--dark;
  margin-bottom: 4px;
}

.sh-search-field > input[type=text]:focus {
  background-color: $sh-color-text--light;
  background-image: url('../../assets/icons/icon_search.png');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding-left: 40px;
}

.sh-search-field > input[type=text]:active {
  border: none;
}

.mat-select-panel,
.mat-menu-panel {
  background: $sh-color-content-background !important;
  box-shadow: $sh-color-content-shadow !important;
  border-radius: 0 !important;
  backdrop-filter: $sh-color-content-backdrop !important;

}

.mat-option.mat-selected:not(.mat-option-disabled) {
  color: $sh-color-text--light !important;
}

.mat-pseudo-checkbox-checked {
  background-color: $sh-color-accent !important;
}

.mat-pseudo-checkbox::after {
  color: $sh-color-text--dark !important;
}

.cargo-container-wrapper {
  display: flex;
  margin: 0 10px;
  gap: 4px;

}

.gargo-sum-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  mat-icon {
    color: $sh-color-danger;
  }
}

.cargo-sum {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 10px;
  font-size: 12px;
  color: $sh-color-text--light;
}

.cargo-container-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;

  span {
    font-size: 10px;
  }
}

.cargo-container-input {
  background: url("/assets/images/container-outline.svg") no-repeat;
  border: none;
  width: 32px;
  height: 30px;
  color: white;
  text-align: center;
  color: $sh-color-accent;

  &:focus {
    outline: none;
  }
}
