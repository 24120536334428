$sh-color-primary: #0A2232;
$sh-color-secondary: #0A3757;
$sh-color-accent: #00daf1;
$sh-color-accent-orange: #ff8a00;
$sh-color-text--light: #FBFEFF;
$sh-color-text--dark: #131A21;
$sh-color-success: #146c43;
$sh-color-info: #ff7d00;
$sh-color-danger: #DE1A1A;
$sh-color-light-gray: #9CA4A8;
$sh-color-dark-gray: #444444;
$sh-color-background-main: #1A252D;
$sh-color-hsl-accent: hsla(185, 100%, 47%, 0.1);
$sh-color-backdrop: rgba(0, 0, 0, 0.45);
$sh-color-content-background: #07253c9b;
$sh-color-content-backdrop: blur(5px);
$sh-color-content-shadow: inset 0 0 40px #1c69a058;
$sh-color--light-blue: #0276d2;

.sh-warning {
  background-color: $sh-color-text--light;
  color: $sh-color-danger;
  width: 80%;
  padding: 20px;
  border:1px solid $sh-color-danger;
  border-radius: 10px;
}

.sh-bg-primary {
  background-color: $sh-color-primary;
}

.sh-bg-secondary {
  background-color: $sh-color-secondary;
}

.sh-bg-secondary-gradient {
  background-image: linear-gradient(to bottom right, $sh-color-secondary, $sh-color-primary);
}

.sh-bg-accent {
  background-color: $sh-color-accent;
}

.sh-bg-transparent {
  background-color: transparent;
}

.sh-border-accent {
  border: 1px solid $sh-color-accent;
}

.sh-gradient-accent {
  opacity: 1;
  background: linear-gradient(to right,  rgba(0,218,241,1) 0%,rgba(10,34,50,0) 100%)!important;
}

.sh-color-primary {
  color: $sh-color-primary;
}

.sh-color {
  &.primary {
    color: $sh-color-primary;
  }
  &.secondary {
    color: $sh-color-secondary;
  }
}

.sh-color-accent {
  color: $sh-color-accent;
}

.sh-color-success {
  color: $sh-color-success;
}

.sh-color-danger {
  color: $sh-color-danger;
}

.sh-text-light {
  color: $sh-color-text--light;
  font-size: 1em;
}

.sh-text-title {
  color: $sh-color-accent;
  font-size: 1em;
}

.sh-color-bg-primary {
  background-color: $sh-color-primary;
}

.sh-color-bg-secondary {
  background-color: $sh-color-secondary;
}

.sh-bg-dark-gray {
  background-color: $sh-color-dark-gray;
}

.sh-bg-main {
  background-color: $sh-color-background-main;
}
