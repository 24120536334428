@import "../basics/index";

.sh-sidebar {
  height: 620px;
  width: 200px;
  position: sticky;
  display: inline-block;
  z-index: 1;
  left: 0;
  top: 0;
  float: left;
  overflow-y: hidden;
  padding: 20px 8px;
  word-wrap: normal;
}

.sh-sidebar a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-family: "Arial";
  font-size: 12px;
  display: block;
  cursor: url("../../assets/images/cursor_pointer.svg"), pointer;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: $sh-color-backdrop !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-color: $sh-color-backdrop !important;
}

@media screen and (max-width: 960px) {
  .sh-sidebar {
    visibility: hidden;
    height: 0;
  }
}
