
.sh-shop-entry {
  background-color: $sh-color-secondary;
  width: 600px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: stretch;
}

.sh-shop-entry__title {
  display: inline-block;
  max-width: 50%;
  margin: 5px;
  color: $sh-color-text--light;
  text-transform: uppercase;
}

.sh-shop-entry__image {
  width: 225px;
  height: auto;
  margin-top: 10px;
  margin-right: 10px;
}

.sh-shop-entry--actions {
  display: flex;
  justify-content: flex-end;
  align-content: space-between;
  align-items: flex-end;
  flex-grow: 1;
  flex-basis: 50px;

  & div {
    display: flex;
    justify-content: flex-start;
    flex-flow: row-reverse;
  }
  & div button {
    flex: 1 0 100px;
  }
}

.sh-shop-entry__form {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: 10px;
}

.sh-shop-entry__content {
  display: flex;
  align-content: flex-end;
  flex-direction: row;
  justify-content: space-between;
}

.sh-shop-entry__image-container {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.sh-shop-entry--inventory__selected {
  background-color: $sh-color-accent;
  color: black;
}

.sh-shop-entry--header {
  display: flex;
  justify-content: space-between;
  &__actions {
    display: flex;
    justify-content: end;
  }
}
