.pl-3 {
  padding-left: 3px;
}

.pr-3 {
  padding-right: 3px;
}

.pb-3 {
  padding-bottom: 3px;
}

.ml-10 {
  margin-left: .8rem;
}

.col {
  flex-grow: 0;
}

.mr-3 {
  margin-right: 3px;
}

.mr-1 {
  margin-right: 1px;
}

.sh-right {
  float: right
}
