@import "colors";

.sh-divider-vertical {
  display: inline-block;
  width: 1px;
  border-right: 1px solid $sh-color-light-gray;
}


.sh-divider-horizontal {
  display: block;
  height: 1px;
  border-bottom: 1px solid $sh-color-light-gray;
}
