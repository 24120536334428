@import "styles/globals";
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;800&display=swap');
@import '~ngx-toastr/toastr';
/* You can add global styles to this file, and also import other style files */

html, body {
  margin: 0;
  @extend .font-univia-pro-book;

  color: $sh-color-text--light;
  box-sizing: border-box;
  cursor: url("assets/images/cursor.svg"), auto;
  cursor: url("assets/images/cursor_pointer.svg"), pointer;
}

body {
  background-image: url("assets/images/bg_grid_sh.png"), url("assets/images/background/space_bg.png");
  background-size: auto;
  background-color: $sh-color-background-main;
}

button:not(:disabled) {
  cursor: url("assets/images/cursor_pointer.svg"), pointer;
}

.sh-active{
  color: $sh-color-accent !important;
  &::before {
    content: '';
    border: 2px solid $sh-color-accent;
    box-shadow: 0 0 6px 2px $sh-color-accent;
    border-radius: 2px;
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 10px;
    right: 22px;
    opacity: 1;
    z-index: 3;
  }
}

.sh-range {
  width: 100%;
  padding: 0;
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { transform:rotate(360deg); } }

//.mat-menu-content {
//  background-color: $sh-color-primary;
//}
