.font-univia-pro-book {
  font-family: univia-pro,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.font-univia-pro-italic {
  font-family: univia-pro,sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
}

.font-univia-pro-book-bold {
  font-family: univia-pro,sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
}

.font-univia-pro-book-italic {
  font-family: univia-pro,sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
}

.font-orbitron-normal {
  font-family: Orbitron, sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
}

@media screen and (max-width: 1199px)  {
  .font-univia-pro-book {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .font-univia-pro-book {
    font-size: 24px;
  }
}
